import _objectSpread from "F:/openy-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { AppMain, Navbar } from "./components";
import ResizeMixin from "./mixin/ResizeHandler";
import { mapState } from 'vuex';
import Cookies from 'js-cookie';
import { mapGetters } from 'vuex';
export default {
  name: 'Layout',
  components: {
    AppMain: AppMain,
    Navbar: Navbar
  },
  mixins: [ResizeMixin],
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState({
    device: function device(state) {
      return state.app.device;
    }
  })), mapGetters(['roles'])), {}, {
    listShow: function listShow() {
      var flag = this.roles.indexOf('all:manage');

      if (flag === -1) {
        return 'no-side';
      } else {
        return 'no-side';
      }
    }
  }),
  mounted: function mounted() {
    if (Cookies.get('theme')) {
      this.$refs.theme.theme = Cookies.get('theme');
      this.$store.dispatch('settings/changeSetting', {
        key: 'theme',
        value: Cookies.get('theme')
      });
    }
  },
  methods: {
    handleClickOutside: function handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', {
        withoutAnimation: false
      });
    }
  }
};