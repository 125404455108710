import _toConsumableArray from "F:/openy-admin/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
var state = {
  miniData: [],
  currentIndex: '',
  //最外层选中的index
  contentIndex: '',
  //导航下的内容选中的index
  edit_id: '',
  //内容编辑的id,
  nav_idx: 0,
  //导航选中
  textFrameShow: false,
  //控制文本弹窗是否显示
  text_obj: {
    fontSize: 17,
    content: '',
    align: 'left',
    type: 60
  },
  //文本内容
  img_obj: {
    extObj: {
      imageLayoutType: 1
    },
    imageList: [],
    type: 61
  },
  //图片内容
  insterImgShow: false,
  notice_obj: {
    contentList: [{
      title: '',
      eventType: 1,
      itemId: ''
    }],
    icon: "iconbuttom-icon57",
    stayTime: 3,
    type: 63
  },
  //公告内容
  isShowNotice: false,
  imgText_obj: {
    imageTextLayoutType: 1002,
    imageTextList: [],
    styleId: '',
    type: 62
  },
  //图文内容
  isShowImgText: false,
  map_obj: {
    addressName: "",
    qqLocation: {
      longitude: 116.44357,
      latitude: 39.9222
    },
    cityName: '',
    address: '',
    phone: '',
    remark: '',
    mapType: 1
  },
  isShowMap: false,
  searchBarOpen: '',
  skinObj: {
    topBgImageUrl: '',
    bgColorType: 1,
    bgColor: ''
  },
  bgMusicObj: {},
  navColorObj: {},
  navLayoutType: 1,
  screenMsg: {},
  isShowContent: false,
  contentConfig: {},
  createShow: true,
  isLocationOpen: 0,
  //是否开启定位
  isShowtitleHead: false,
  titleHeadObj: {},
  isShowModule: false
};
var mutations = {
  SET_MINI_DATA: function SET_MINI_DATA(state, obj) {
    state.miniData = obj;
  },
  setEdit_ID: function setEdit_ID(state, obj) {
    state.edit_id = obj;
  },
  setNavIdx: function setNavIdx(state, obj) {
    state.nav_idx = obj;
  },
  upDataStore: function upDataStore(state, obj) {
    state[obj.key] = obj.value;
  },
  upDataArrayStore: function upDataArrayStore(state, obj) {
    // if (state.miniData[state.miniData.length] && state.miniData[state.miniData.length].type == 200) return
    state.miniData.map(function (e) {
      if (e.type == 200) {
        var _e$classAndNavDataLis;

        (_e$classAndNavDataLis = e.classAndNavDataList).push.apply(_e$classAndNavDataLis, _toConsumableArray(obj.classAndNavDataList));
      }
    });
  },
  upAllDataArrayStore: function upAllDataArrayStore(state, obj) {
    state.miniData.map(function (e) {
      if (e.type == 200) {
        var _e$classAndNavDataLis2;

        (_e$classAndNavDataLis2 = e.classAndNavDataList).push.apply(_e$classAndNavDataLis2, _toConsumableArray(obj));
      }
    });
  },
  upNavItemStore: function upNavItemStore(state, obj) {
    state.miniData.map(function (e) {
      if (e.type == 200) {
        e.classAndNavDataList.push(obj);
      }
    });
  },
  fgNavItemStore: function fgNavItemStore(state, obj) {
    state.miniData.map(function (e) {
      if (e.type == 200) {
        e.classAndNavDataList = [obj];
      }
    });
  }
};
var obj = {};
var actions = {
  // 初始化数据
  setPrjectData: function setPrjectData(_ref, data) {
    var commit = _ref.commit,
        state = _ref.state,
        dispatch = _ref.dispatch;
    var projectData = data;

    if (!projectData) {
      projectData = [];
    }

    commit('SET_MINI_DATA', projectData);
    commit('setNavIdx', 0);
  },
  // 添加内容
  addElement: function addElement(_ref2, data) {
    var commit = _ref2.commit;
    initAddDataItem(data);
  },
  // 外层数据排序
  sortProjectData: function sortProjectData(_ref3, data) {
    var commit = _ref3.commit;
    state.miniData.splice(data.index, 1);
    state.miniData.unshift(data.item);
  },
  // 外层数据删除
  delProjectData: function delProjectData(_ref4, data) {
    var commit = _ref4.commit;
    state.miniData.splice(data, 1);
  },
  // 编辑/添加新内容，关闭弹层
  setNewContentItem: function setNewContentItem(_ref5, data) {
    var commit = _ref5.commit;

    if (data.value.item != '') {
      state[data.key] = data.value.item;
    }

    if (data.value.optionType == 'save') {
      if (state.edit_id != '') {
        editDataItem(state[data.key]);
      } else {
        initAddDataItem(state[data.key]);
      }
    }

    if (data.value.optionType == 'edit') {
      obj = JSON.parse(JSON.stringify(state[data.key]));
    }

    if (data.value.optionType == 'cancle') {
      editDataItem(obj);
    }

    if (data.key == "notice_obj") {
      state.isShowNotice = data.value.bool;
    } else if (data.key == 'titleHeadObj') {
      state.isShowtitleHead = data.value.bool;
    } else if (data.key == 'text_obj') {
      state.textFrameShow = data.value.bool;
    } else if (data.key == 'img_obj') {
      state.insterImgShow = data.value.bool;
    } else if (data.key == 'imgText_obj') {
      state.isShowImgText = data.value.bool;
    } else if (data.key == 'map_obj') {
      state.isShowMap = data.value.bool;
    } else if (data.key == 'contentConfig') {
      state.isShowContent = data.value.bool;
    }
  }
};
export default {
  state: state,
  mutations: mutations,
  actions: actions
}; // 新增内容

var initAddDataItem = function initAddDataItem(data) {
  if (state.currentIndex === '' && state.contentIndex === '') {
    state.miniData.unshift(data);
  }

  if (state.currentIndex !== '') {
    if (state.miniData.length - 1 === state.currentIndex) {
      if (state.contentIndex == '') {
        state.miniData.map(function (e) {
          if (e.type == 200) {
            e.classAndNavDataList.map(function (h, i) {
              if (state.nav_idx == i) {
                h.dataList.unshift(data);
              }
            });
          }
        });
      }
    } else {
      state.miniData.splice(state.currentIndex + 1, 0, data);
    }
  }

  if (state.contentIndex !== '') {
    state.miniData.map(function (e) {
      if (e.type == 200) {
        e.classAndNavDataList.map(function (h, i) {
          if (state.nav_idx == i) {
            h.dataList.splice(state.contentIndex + 1, 0, data);
          }
        });
      }
    });
  }
}; // 编辑已有的内容


var editDataItem = function editDataItem(data) {
  state.miniData.map(function (e) {
    if (e.id == state.edit_id) {
      Object.assign(e, data);
    } else {
      if (e.type == 200) {
        if (e.classAndNavDataList) {
          e.classAndNavDataList.map(function (h) {
            if (h.dataList) {
              h.dataList.map(function (k) {
                if (k.id == state.edit_id) {
                  Object.assign(k, data);
                }
              });
            }
          });
        }
      }
    }
  });
};